.ant-layout-header.sg-layout-header {
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  position: sticky;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}
