.ant-form.sg-login-page-form {
  height: 100vh;
  display: flex;
  padding: 30px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  background-color: hsla(0, 0%, 100%, .72);
}

.ant-form.sg-login-page-form .ant-alert.sg-login-page-form-alert {
  top: 8px;
  left: 8px;
  position: absolute;
  width: calc(100% - 16px);
}
